import * as commonTypes from "@/store/common/mutationTypes";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

const actions = {
  /*
   * 初期データを受け取る
   ***********************************/
  /**
   * 初期値の取得
   */
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    let apiInfo = [];
    if (getters["common/isAuthorized"]({ name: "news", type: "view" })) {
      apiInfo.push({
        url: "/news/published",
        action: "setNewsList",
      });
    }
    if (getters["common/isAuthorized"]({ name: "halls", type: "view" })) {
      apiInfo.push({
        url: "/halls",
        action: "setHallList",
      });
    }

    const success = await Promise.all(
      apiInfo.map(
        async (v) =>
          await dispatch(v.action, await dispatch("common/getInitialData", { apiUrl: v.url }))
      )
    );

    if (success.every((v) => v == true)) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
    } else {
      commit("common/" + commonTypes.ERROR);
      dispatch("modal/showErrorModal", {
        text: state.modal.errorModal.text || MESSAGE_CONFIG.error,
      });
    }
  },

  /*
   * 初期データを state に反映
   ***********************************/
  // お知らせ & 稼働状況
  async setNewsList({ commit }, { data }) {
    if (!data) return false;
    commit("SET_NEWS_DATA", { data: data.data });
    commit("SET_NEWS_MASTER", { master: data.master });
    commit("SET_SYSTEM_STATUS", { status: data.system_status });
    return true;
  },
  // 式場一覧
  async setHallList({ commit }, { data }) {
    if (!data) return false;
    commit("SET_HALL_DATA", { data: data.data });
    commit("SET_HALL_MASTER", { master: data.master });
    return true;
  },
};

export default actions;
