import Vue from "vue";
import Router from "vue-router";
import TopView from "@/views/TopView.vue";
import ErrorView from "@/views/ErrorView.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "top",
      path: "/",
      component: TopView,
    },
    {
      name: "error",
      path: "*",
      component: ErrorView,
    },
  ],
});
