<template>
  <div>
    <modal-loading v-if="loading" />
    <top-init v-if="isShowInit" />
    <div v-else-if="!!role">
      <top-admin v-if="isShowAdmin" />
      <top-company v-else-if="isShowCompany" />
      <top v-else />
    </div>
    <modal-state />
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode.js";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

import ModalLoading from "@/components/molecules/common/ModalLoading.vue";
import ModalState from "@/components/organisms/common/ModalState.vue";
import Top from "@/components/organisms/top/Top.vue";
import TopInit from "@/components/organisms/top/init/TopInit.vue";
import TopAdmin from "@/components/organisms/top/admin/TopAdmin.vue";
import TopCompany from "@/components/organisms/top/company/TopCompany.vue";

import { getSelectedHallId } from "@/assets/common/PageConfig.js";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    ModalLoading,
    ModalState,
    Top,
    TopInit,
    TopAdmin,
    TopCompany,
  },
  computed: {
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapGetters("common", ["role", "isViewableOwnHall", "isViewableAnyHall"]),
    isShowInit: function() {
      if (!this.role) return false;
      return (
        this.role == API_CODE.role.temporary ||
        this.role == API_CODE.role.initial
      );
    },
    isShowAdmin: function() {
      if (getSelectedHallId()) return false; // 管理画面->式場アカウント
      return this.isViewableAnyHall;
    },
    isShowCompany: function() {
      if (getSelectedHallId()) return false; // 企業アカウント->式場アカウント
      return this.isViewableOwnHall;
    },
  },
  mounted: function() {
    if ("permission" in this.$store.state.route.query) {
      this.showErrorModal({
        text: MESSAGE_CONFIG.response.accessDenied,
      });
      this.$router.push(location.pathname); // query 削除
    }
  },
  methods: {
    ...mapActions("modal", ["showErrorModal"]),
  },
};
</script>

<style scoped lang="scss"></style>
