import Vue from "vue";
import App from "@/pages/top/App.vue";
import router from "@/pages/top/router.js";
import store from "@/store/top";

import { setGuard } from "@/lib/router/index.js";
setGuard(router);

import { sync } from "vuex-router-sync";
sync(store, router);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
