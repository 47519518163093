<template>
  <div class="topInit" v-if="!loading">
    <page-title icon="flight_takeoff" title="初期設定準備" />
    <step-unit
      v-for="(v, i) in Object.values(step)"
      :key="`step-${i}`"
      :button-text="v.buttonText"
      :name="v.name"
      :num="i + 1"
      :is-checked="v.isChecked"
      :is-disabled="v.isDisabled"
      :path="v.path"
      :sub-text="v.subText"
      :text="v.text"
    />
    <step-unit
      class="option"
      v-for="(v, i) in Object.values(option)"
      :key="`option-${i}`"
      :button-text="v.buttonText"
      :name="v.name"
      :is-disabled="v.isDisabled"
      :is-optional="true"
      :path="v.path"
      :sub-text="v.subText"
      :text="v.text"
    />
    <div class="bottom">
      <span class="bottomText">初期設定準備が完了すると、弊社で初期設定を開始いたします</span>
      <action-button
        class="bottomButton"
        text="初期設定 準備完了"
        :is-disabled="Object.values(step).some((v) => !v.isChecked)"
        :is-large="true"
        :is-save="true"
        @clickHandler="complete"
      />
    </div>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import PageTitle from "@/components/atoms/common/PageTitle.vue";
import StepUnit from "@/components/molecules/top/top-init/StepUnit.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    PageTitle,
    StepUnit,
  },
  data() {
    return {
      step: {
        masterAccount: {
          name: "パスワードの変更",
          path: "/master-account",
          text: "初期パスワードを変更してください",
          buttonText: "変更する",
          isChecked: false,
          isDisabled: false,
        },
        mediaAccount: {
          name: "媒体アカウント設定",
          path: "/media-account",
          text: "連携する媒体の ID とパスワードを入力してください",
          buttonText: "設定する",
          isChecked: false,
          isDisabled: true,
        },
      },
      option: {
        hallInitSetting: {
          name: "残席のデフォルト設定",
          path: "/hall-init-setting",
          text: "新規登録時の残席設定をご確認の上、必要な場合は変更してください",
          subText: "",
          buttonText: "変更する",
          isDisabled: true,
        },
      },
    };
  },
  computed: {
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapGetters("topInit", { m: "mediaAccount", h: "hallInitSetting" }),
    ...mapGetters("common", ["role"]),
  },
  async mounted() {
    await this.init();
    if (!this.role) return;
    const isTemporaryUser = this.role == API_CODE.role.temporary;
    // パスワードの変更
    this.step.masterAccount.isChecked = !isTemporaryUser;
    // 媒体アカウントの設定
    this.step.mediaAccount.isChecked =
      this.m && Object.values(this.m.data).every((v) => !v.in_usage || v.login_status === 2);
    this.step.mediaAccount.isDisabled = isTemporaryUser;
    // 残席のデフォルト設定
    this.option.hallInitSetting.isDisabled = isTemporaryUser;
    if (!isTemporaryUser) {
      const stockNum = this.h ? `${this.h.data.default_stock_available_num} 枠` : "";
      const stock = this.h
        ? this.h.master.availability_num[this.h.data.default_stock_availability]
        : "";
      this.option.hallInitSetting.subText = `
      ${this.mediaName("zexy")}：${stockNum}<br />
      ${this.mediaName("ksm")}・
      ${this.mediaName("wp")}・
      ${this.mediaName("mynavi")}：${stock}`;
    }
  },
  methods: {
    ...mapActions("topInit", { init: "getInitialData", ready: "ready" }),
    complete: function () {
      this.ready();
    },
    mediaName: function (name) {
      if (!this.m) return "";
      const mediaId = API_CODE.media[name];
      return this.m.master[mediaId].name;
    },
  },
};
</script>

<style scoped lang="scss">
.topInit {
  font-size: 12px;
  .bottom {
    color: $COLOR_SUB_TEXT;
    margin-top: $GRID * 2;
    text-align: center;
    &Button {
      font-size: 12px;
      margin: $GRID * 2 auto auto;
    }
  }
  .option {
    margin-top: $GRID * 2;
  }
}
</style>
