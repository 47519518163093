<template>
  <div class="hallUnit" :class="className">
    <div class="hallUnit_top">
      <div class="hallUnitTitle">
        <span class="hallUnitTitle_icon material-icons">location_city</span>
        <div class="hallUnitTitleInr">
          <p class="hallUnitTitle_sub">{{ data.hall_id }}・{{ data.city }}</p>
          <p class="hallUnitTitle_name">{{ data.hall_name }}</p>
        </div>
      </div>
      <div v-if="isEmpty" class="hallUnitEmpty">
        <slot name="empty" />
      </div>
      <hall-list-nav v-else-if="isViewableOwnHall" :id="data.hall_id" />
      <div v-else class="hallUnitDetail">
        <state-unit
          class="hallUnitDetail_state"
          :is-disabled="!isAuthorized || !isAuthorized.settings"
          :is-save-button="isPublished"
          :is-small="isPublished"
          :is-ready="isPrepare"
          :is-end="isSuspended"
          :is-show-usage="true"
          :date="
            data.suspended_at ||
            data.usage_started_at ||
            data.preparation_started_at
          "
          @click="click"
        />
        <hall-list-nav
          :id="data.hall_id"
          :is-restricted="isBeforeImport"
          @serviceModalOpen="$emit('serviceModalOpen')"
        />
        <div class="hallUnitDetail_bottom">
          <slot name="detail" />
        </div>
      </div>
    </div>
    <div class="hallUnit_bottom">
      <slot name="bottom" />
    </div>
    <slot name="hallMenu" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import isSuspended from "@/store/hall-admin/helper/is-suspended.js";

import UnitMenu from "@/components/molecules/common/ListUnitMenu.vue";
import MenuBtn from "@/components/atoms/common/MenuButton.vue";
import HallListNav from "@/components/organisms/common/HallListNav.vue";
import StateUnit from "@/components/molecules/common/StateUnit.vue";

export default {
  components: {
    UnitMenu,
    MenuBtn,
    HallListNav,
    StateUnit,
  },
  props: {
    data: Object,
    media: Object,
    isAuthorized: Object,
  },
  computed: {
    ...mapGetters({
      isViewableOwnHall: "common/isViewableOwnHall",
      isViewableAnyHall: "common/isViewableAnyHall",
      isPrepareHall: "hallAdmin/isPrepare",
    }),
    isEmpty() {
      // 開始前
      return this.isViewableAnyHall && this.data.preparation_started_at == null;
    },
    isPrepare() {
      // 準備中
      return this.isViewableAnyHall && this.isPrepareHall(this.data);
    },
    isBeforeImport() {
      // データインポート前
      return this.isViewableAnyHall && this.data.import_ended_at == null;
    },
    isPublished() {
      // 公開完了
      return (
        this.isViewableAnyHall &&
        this.data.published_at != null &&
        this.data.in_preparation_target.length > 0
      );
    },
    inUse() {
      // 利用開始
      return (
        this.isViewableAnyHall &&
        this.data.usage_started_at != null &&
        !isSuspended(this.data.suspended_at)
      );
    },
    isSuspended() {
      // 利用終了
      return this.isViewableAnyHall && isSuspended(this.data.suspended_at);
    },
    className() {
      return {
        isEmpty: this.isEmpty || this.isSuspended,
        isPrepare: this.isPrepare,
        inUse: this.inUse,
      };
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.hallUnit {
  @include Box();
  box-sizing: border-box;
  min-height: $GRID * 7;
  padding: $GRID * 0.5 $GRID * 0.5 $GRID * 0.5 $GRID;
  position: relative;
  width: 100%;
  &_top {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &Title {
    align-items: center;
    display: flex;
    flex: 1;
    &_icon {
      background: $COLOR_KEY;
      border-radius: 50%;
      color: #fff;
      line-height: 38px;
      padding: 0 0 2px 2px;
      text-align: center;
      width: 40px;
      .isPrepare & {
        background: $COLOR_WARNING;
      }
      .inUse & {
        background: $COLOR_SUBMIT;
      }
      .isEmpty & {
        background: $COLOR_SUB_TEXT;
      }
    }
    &Inr {
      color: $COLOR_KEY;
      flex: 1;
      margin: 0 $SUB_GRID 0 $GRID;
      .isEmpty & {
        color: $COLOR_SUB_TEXT;
      }
    }
    &_sub {
      font-size: 14px;
    }
    &_name {
      font-size: 16px;
      font-weight: bold;
    }
  }
  &Empty {
    margin: 0 $GRID * 0.5 $SUB_GRID 0;
  }
  &Detail {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: $GRID * 40;
    &_bottom {
      width: 100%;
    }
    &_state {
      position: relative;
      top: $GRID * 0.5;
    }
  }
  &.isEmpty {
    background: $BG_COLOR_SUB;
  }
  & + & {
    margin-top: $GRID;
  }
}
</style>
