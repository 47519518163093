const getters = {
  hallInitSetting: (state) => {
    return state.hallInitSetting;
  },
  mediaAccount: (state) => {
    return state.mediaAccount;
  },
};

export default getters;
