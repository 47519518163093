const mutations = {
  SET_MEDIA_ACCOUNT(state, payload) {
    state.mediaAccount = payload;
  },
  SET_HALL_INIT_SETTING(state, payload) {
    state.hallInitSetting = payload;
  },
};

export default mutations;
