<template>
  <div v-if="!loading">
    <info-unit class="topInfo" v-if="!isInitialSetting" :news="news" :system-status="systemStatus" />
    <div class="topMedia">
      <block-title icon="location_city" title="式場一覧" />
      <hall-unit v-for="v in hall.list" :key="v.hall_id" :data="v" :media="hall.master.media" />
    </div>
  </div>
</template>

<script>
import InfoUnit from "@/components/organisms/top/InfoUnit.vue";
import HallUnit from "@/components/organisms/common/HallUnit.vue";
import WaitingUnit from "@/components/organisms/top/WaitingUnit.vue";
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    InfoUnit,
    HallUnit,
    WaitingUnit,
    BlockTitle,
  },
  data: function() {
    return {};
  },
  props: {},
  computed: {
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapState("topCompany", {
      hall: (state) => state.hall,
      news: (state) => state.news,
      systemStatus: (state) => state.system_status,
    }),
    ...mapGetters("common", ["isInitialSetting"]),
  },
  methods: {
    ...mapActions("topCompany", { init: "getInitialData" }),
    ...mapActions("modal", ["showErrorModal"]),
  },
  mounted: function() {
    this.init();
  },
};
</script>

<style lang="scss"></style>
